import React, { PureComponent } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { downloadApp } from 'src/store/app/actions';
import { t } from 'src/modules/i18n';
import Analytics from 'src/modules/analytics';
import * as COLORS from 'src/constants/colors';
import { COMPATIBILITY, PALM, TAROT, ZODIAC } from 'src/constants/remote-config';
import Header from 'src/components/header';
import Button from 'src/components/button-with-animation';

import ContentBox from './components/content-box';

const BLOCKS = {
  [ZODIAC]: React.lazy(() => import('./components/zodiac')),
  [PALM]: React.lazy(() => import('./components/palm')),
  [TAROT]: React.lazy(() => import('./components/tarot')),
  [COMPATIBILITY]: React.lazy(() => import('./components/compatibility')),
};

class MainScreen extends PureComponent {
  static propTypes = {
    downloadApp: PropTypes.func.isRequired,
  };

  state = {
    containerHeight: 700,
  }


  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ containerHeight: window.innerHeight });
      window.scrollTo(0, 0);
    }, 50);
    Analytics.trackEvent('page', 'loaded');
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.skipTimeout);
  }

  renderBlocks = () => {
    const { pageStack } = this.props;
    return pageStack.map(item => {
      const Component = BLOCKS[item];
      return <ContentBox
        title={t(`WIDGETS.${item.toUpperCase()}.TITLE`)}
        withButton={item === ZODIAC || item === COMPATIBILITY}
      >
        <Component key={item} />
      </ContentBox>;
    });
  };

  renderEmptyPage = () => {
    const { btnTitle } = this.props;
    return <Button
      flare
      buttonStyle={styles.button}
      title={btnTitle}
      onClick={this.handlePress} />;
  };

  handlePress = (e) => {
    const { downloadApp } = this.props;
    e.preventDefault();
    e.stopPropagation();
    Analytics.trackEvent("button", "click");
    downloadApp();
  };

  handlePageClick = () => {
    const { downloadApp, pageClickMode, pageStack } = this.props;
    const emptyPage = !pageStack.length;
    if (pageClickMode && !emptyPage) {
      Analytics.trackEvent("page", "click");
      downloadApp();
    }
  };

  render() {
    const { pageStack, btnTitle, subTitle } = this.props;
    const { containerHeight } = this.state;
    const emptyPage = !pageStack.length;
    const rootStyle = [
      styles.root,
      { height: containerHeight },
      emptyPage && styles.emptyRoot
    ];
    return (
      <div onClick={this.handlePageClick} style={rootStyle}>
        <React.Suspense fallback={<div style={styles.suspence}><div className={'loader'} /></div>}>
          {emptyPage ? this.renderEmptyPage() :
            <>
              <main style={styles.main}>
                <Header subTitle={subTitle} />
                {this.renderBlocks()}
              </main>
              <Button
                flare
                buttonStyle={styles.button}
                title={btnTitle}
                onClick={this.handlePress} />
            </>
          }
        </React.Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    remoteConfig: { pageStack, btnTitle, pageClickMode, subTitle },
  } = state;

  return {
    pageStack,
    pageClickMode,
    btnTitle: btnTitle || t('BUTTON.TITLE'),
    subTitle: subTitle || t('HEADER.SUBTITLE'),
  };
};
const mapDispatchToProps = {
  downloadApp,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), Radium)(MainScreen);

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.JAGGER,
    backgroundImage: `url(${require('./images/zodiac.png').default})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  emptyRoot: {
    backgroundImage: null,
    backgroundColor: COLORS.WHITE,
    justifyContent: 'center',
  },
  main: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    "@media only screen and (max-width: 375px) and (max-height: 560px)": {
      padding: '35px 0',
    },
    padding: '50px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'scroll',
  },
  button: {
    maxWidth: 340,
    marginTop: 30,
    position: 'sticky',
    "@media only screen and (max-width: 375px) and (max-height: 560px)": {
      bottom: 20,
    },
    bottom: 40,
    zIndex: 10,
  },
  suspence: {
    flex: 1,
    display: 'flex',
  },
};
