export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const BLACK_20 = 'rgba(0, 0, 0, 0.2)';
export const BLACK_30 = 'rgba(0, 0, 0, 0.3)';
export const TRANSPARENT = 'transparent';
export const BRIGHT_TEAL = '#00eedb';
export const CERULEAN = '#0082df';
export const GIGAS = '#4643ae';
export const JAGGER = '#230D51';
export const BRIGHT_TURQUOISE = '#1FE7E8';
export const BRIGHT_TURQUOISE_2 = '#0df8dd';
export const BLUEBERRY = '#5550a9';
export const BLUEBERRY_20 = 'rgba(24, 4, 60, 0.2)';
export const PORTAGE = '#9994f4';
export const YELLOW = '#e0e339';
export const GREEN = '#1ad386';
export const BLUE = '#0fccff';
export const BROWN = '#94593f';
export const RED = '#d9193c';
export const ORANGE = '#e79111';
export const PINK = '#ff4ac9';
export const GRAY = '#919294';
export const BEIGE = '#dfb79a';
export const GOLDEN = '#d1b30d';
export const EMERALD = '#147d84';
export const LIGHT_MAGENTA = '#d00d68';
export const BURGUNDY = '#ab2115';
export const CORAL = '#e3323c';
export const COPPER = '#bd6524';
export const OLIVE = '#aab53a';
export const SILVER = '#bcc2c5';
export const PURPLE = '#8738ec';
export const KHAKI = '#4c6a25';
export const LILAC = '#977ce2';
export const TURQUOISE = '#4ab4c8';
export const MARRON = '#ae3149';
export const COQUELICOT = '#e54926';
export const HONEYDEW = '#cae9b7';
export const CHARCOAL = '#484d4e';
export const BRONZE = '#b57c3e';
export const AZURE = '#3782fe';
export const RED_BERRY = '#721322';
export const VIOLET_50 = 'rgba(30, 9, 67, .5)';
export const BLUE_VIOLET = '#5946be';
export const GOVERNOR_BAY = '#3935ab';
export const ASTRONAUT = '#2b2472';
export const BAY_OF_MANY_78 = 'rgba(46,40,127,0.78)';
export const BAY_OF_MANY_51 = 'rgba(50,44,142,0.51)';
export const GOVERNOR_BAY_01 = 'rgba(57,53,172,0.01)';

export const DAY_COLORS = {
  1: YELLOW,
  2: GREEN,
  3: BLUE,
  4: BROWN,
  5: WHITE,
  6: RED,
  7: ORANGE,
  8: PINK,
  9: GRAY,
  10: BLACK,
  11: BEIGE,
  12: GOLDEN,
  13: EMERALD,
  14: LIGHT_MAGENTA,
  15: BURGUNDY,
  16: CORAL,
  17: COPPER,
  18: OLIVE,
  19: SILVER,
  20: PURPLE,
  21: KHAKI,
  22: LILAC,
  23: TURQUOISE,
  24: MARRON,
  25: COQUELICOT,
  26: HONEYDEW,
  27: CHARCOAL,
  28: BRONZE,
  29: AZURE,
  30: RED_BERRY,
};
