import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import app from './app';
import remoteConfig from './remote-config';

export default function configureStore() {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const rootReducer = combineReducers({
    app: persistReducer({ key: 'app', storage, blacklist: ['loaded'] }, app),
    remoteConfig: persistReducer({ key: 'remoteConfig', storage }, remoteConfig),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
}
