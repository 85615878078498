import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DeviceProps from '@magnus/react-native-device-props';

import { version } from '../package.json';

import 'react-alice-carousel/lib/alice-carousel.css';
import './index.css';

import * as serviceWorker from './serviceWorker';

import configureStore from './store';
import { load } from './store/app/actions';

import { Localization } from './modules/i18n';

window.__DEV__ = !!process.env.REACT_APP_DEV;

export default function (App) {
  const { store, persistor } = configureStore();

  Localization.init();

  DeviceProps.setAppVersion(version);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  store.dispatch(load(persistor));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
