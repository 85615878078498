import React, { forwardRef, } from 'react';
import Radium from 'radium';
import { compose } from 'recompose';

import * as COLORS from 'src/constants/colors';

import Flare from './flare';

export default compose(
  Radium,
  forwardRef,
)(({
  onClick,
  title,
  subTitle,
  disabled,
  flare,
  buttonStyle,
  buttonTitleStyle,
  buttonSubtitleStyle,
  contentContainerStyle,
  children,
  iconRight,
  ...other
}, ref) => {
  return (
    <button
      {...other}
      ref={ref}
      onClick={onClick}
      style={[styles.button, buttonStyle, disabled ? styles.disabled : null]}
      disabled={disabled}
    >
      <div style={contentContainerStyle}>
        <p style={[styles.buttonTitle, buttonTitleStyle]}>{title}</p>
        {subTitle ? (
          <p style={[styles.buttonSubtitle, buttonSubtitleStyle]}>
            {subTitle}
          </p>
        ) : null}
        {iconRight}
        {!disabled && flare ?
          <Flare />
          : null}
      </div>
      {children}
    </button>
  );
})

const styles = {
  button: {
    background: `linear-gradient(82deg, ${COLORS.BRIGHT_TEAL} -16%, ${COLORS.CERULEAN} 100%)`,
    border: '0',
    minHeight: 60,
    borderRadius: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    transitionProperty: 'opacity',
    ':focus': {
      outline: 0,
    },
    ':active': {
      opacity: 0.75,
    },
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer'
  },
  buttonTitle: {
    fontSize: '24px',
    fontFamily: 'Philosopher',
    textShadow: `0 2px 0 ${COLORS.BLACK_30}`,
    fontWeight: '600',
    textAlign: 'center',
    color: COLORS.WHITE,
  },
  buttonSubtitle: {
    fontSize: 15,
    color: COLORS.WHITE,
  },
  disabled: {
    backgroundImage: COLORS.BLUEBERRY,
  },
};
