import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import * as COLORS from 'src/constants/colors';

import Button from './button';

class ContentBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func,
    withButton: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
  };

  handlePress = () => console.warn('woot')

  render() {
    const { title, onPress, withButton, children, style } = this.props;
    return (
      <div style={[styles.box, style]} onClick={onPress}>
        <h4 style={styles.header}>{title}</h4>
        {children}
        <div style={styles.overlay}>
          {withButton ? (
            <div style={styles.buttonContainer}>
              <Button />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Radium(ContentBox);

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    fontFamily: 'Philosopher',
    fontSize: '24px',
    fontWeight: 'bold',
    color: COLORS.WHITE,
    textShadow: `0 2px 0 ${COLORS.BLACK_20}`,
    margin: '0 0 15px',
    padding: 0,
  },
  box: {
    backgroundColor: COLORS.BLUE_VIOLET,
    borderRadius: 15,
    padding: '18px',
    position: 'relative',
    marginTop: '30px',
    width: 343,
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '76px 0 24px',
    background: `linear-gradient(0deg, ${COLORS.ASTRONAUT} 0%,
                                       ${COLORS.BAY_OF_MANY_78} 40%,
                                       ${COLORS.BAY_OF_MANY_51} 70%,
                                       ${COLORS.GOVERNOR_BAY_01} 100%)`,
    borderRadius: '0px 0px 15px 15px',
  },
};
