import React, { PureComponent } from 'react';
import Radium from 'radium';

import * as COLORS from 'src/constants/colors';
import { APP_NAME } from 'src/constants/general';

class Header extends PureComponent {
  render() {
    const { subTitle } = this.props;
    return (
      <header style={styles.header}>
        <h1 style={styles.title}>{APP_NAME}</h1>
        <div style={styles.subtitle}>{subTitle}</div>
      </header>
    );
  }
}

export default Radium(Header);

const styles = {
  header: {
    padding: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '56px',
    fontWeight: '600',
    lineHeight: '0.71',
    letterSpacing: '-1.8px',
    textAlign: 'center',
    color: COLORS.WHITE,
  },
  subtitle: {
    marginTop: 40,
    fontFamily: 'Philosopher',
    fontSize: '26px',
    fontWeight: '600',
    lineHeight: '1.19',
    textAlign: 'center',
    color: COLORS.WHITE,
    width: 343,
  },
};
