import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import * as COLORS from 'src/constants/colors';
import { t } from 'src/modules/i18n';

class Button extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
  }
  render() {
    const { onPress } = this.props;
    return (
      <button style={styles.button} onClick={onPress}>
        <span style={styles.title}>{t('CONTENT_BOX.BUTTON')}</span>
      </button>
    );
  }
}

export default Radium(Button);

const styles = {
  button: {
    width: '170px',
    height: '40px',
    backgroundColor: COLORS.VIOLET_50,
    borderWidth: 0,
    borderRadius: '20px',
    cursor: 'pointer',
    ':focus': {
      outline: 0,
    },
    ':active': {
      opacity: 0.75,
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    color: COLORS.BRIGHT_TURQUOISE_2,
  },
};
