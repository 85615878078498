import { SET_LOADED } from './types';

export const initialState = {
  loaded: false,
  link: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: true,
      };

    default:
      return state;
  }
}
